// https://github.com/kamranahmedse/driver.js
import i18n from '@/locale'; // Internationalization

const steps = () => [
  {
    element: '#actionColumn',
    popover: {
      title: i18n.t('guideIndex.actionColumn.title'),
      description: i18n.t('guideIndex.actionColumn.description'),
      position: 'top',
    },
  },
  {
    element: '#exportFileCol',
    popover: {
      title: i18n.t('guideIndex.exportFileCol.title'),
      description: i18n.t('guideIndex.exportFileCol.description'),
      position: 'top',
    },
  },
  {
    element: '#pagination',
    popover: {
      title: i18n.t('guideIndex.pagination.title'),
      description: i18n.t('guideIndex.pagination.description'),
      position: 'top',
    },
  },
  {
    element: '#resultsPerPage',
    popover: {
      title: i18n.t('guideIndex.resultsPerPage.title'),
      description: i18n.t('guideIndex.resultsPerPage.description'),
      position: 'top',
    },
    padding: 40,
  },
];

export default steps;
