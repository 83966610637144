<template>
  <!-- eslint-disable max-len -->
  <v-navigation-drawer
    v-model="sidebarInterno"
    absolute
    temporary
    right
    width="512"
    @input="toggleSideBarPai"
  >
    <template v-slot:prepend>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            <v-row justify="space-between">
              <v-col>
                <span class="text-h4">
                  {{ $t('Filtros') }}
                </span>
              </v-col>
              <v-col class="d-flex flex-row-reverse">
                <v-btn
                  color="pink"
                  dark
                  x-small
                  @click.stop="toggleSideBar"
                >
                  X
                </v-btn>
              </v-col>
            </v-row>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>

    <v-divider />

    <v-row
      align="baseline"
      justify="center"
    >
      <template v-for="(coluna, colunaIndex) in tabelaEnvio">
        <v-col
          v-if="!coluna.isColumn &&
            coluna.filtro.able &&
            coluna.filtro.posicao === 'sideBar' &&
            coluna.filtro.type === 'string'"
          :key="colunaIndex"
          :cols="12"
          :md="6"
          :class="coluna.filtro.column.classe"
        >
          <v-text-field
            v-model="valoresEditaveis[colunaIndex]"
            dense
            :rules="coluna.filtro.rules"
            :label="coluna.filtro.label"
            @keyup.enter="apiSearch()"
          />
        </v-col>
        <v-col
          v-if="!coluna.isColumn &&
            coluna.filtro.able &&
            coluna.filtro.posicao === 'sideBar' &&
            coluna.filtro.type === 'select'"
          :key="coluna.item"
          :cols="12"
          :md="6"
          :class="coluna.filtro.column.classe"
        >
          <v-autocomplete
            v-model="valoresEditaveis[colunaIndex]"
            :items="coluna.filtro.items"
            :label="coluna.filtro.label"
            :multiple="coluna.filtro.multiple"
            item-text="texto"
            item-value="id"
          />
        </v-col>
        <v-col
          v-if="!coluna.isColumn &&
            coluna.filtro.able &&
            coluna.filtro.posicao === 'sideBar' &&
            coluna.filtro.type === 'date'"
          :key="coluna.item"
          :cols="12"
          :md="6"
          :class="coluna.filtro.column.classe"
        >
          <v-menu
            v-model="menu1"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{on, attrs}">
              <v-text-field
                v-model="valoresEditaveis[colunaIndex][0]"
                dense
                :label="`${coluna.filtro.label} inicial`"
                readonly
                v-bind="attrs"
                class="mx-5 mb-0 mt-1"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="valoresEditaveis[colunaIndex][0]"
              @input="menu1 = false"
            />
          </v-menu>
          <v-menu
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{on, attrs}">
              <v-text-field
                v-model="valoresEditaveis[colunaIndex][1]"
                dense
                :label="`${coluna.filtro.label} final`"
                readonly
                v-bind="attrs"
                class="mx-5 my-0"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="valoresEditaveis[colunaIndex][1]"
              @input="menu2 = false"
            />
          </v-menu>
        </v-col>
      </template>
      <v-col
        cols="auto"
        class="my-0 py-0"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{on, attrs}">
            <v-btn
              x-small
              v-bind="attrs"
              class="py-4 mx-1 red lighten-4"
              :disabled="tableLoading"
              v-on="on"
              @click="clearFilters()"
            >
              <v-icon dark>
                mdi-filter-remove
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('clearFilters') }}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{on, attrs}">
            <v-btn
              x-small
              v-bind="attrs"
              :disabled="tableLoading"
              class="py-4 mx-1 primary"
              v-on="on"
              @click="apiSearch()"
            >
              <v-icon dark>
                mdi-magnify
              </v-icon>
              <span
                v-if="retornoApi.length > 0"
                class="ml-2"
              >
                ({{ total }})
              </span>
            </v-btn>
          </template>
          <span>{{ $t('button.search') }}</span>
        </v-tooltip>
      </v-col>
    </v-row>
  </v-navigation-drawer>
</template>

<script>
import Vue2Filters from 'vue2-filters';

export default {
  name: 'BuscaResultadoTabelaApiSideBar',
  mixins: [Vue2Filters.mixin],
  props: {
    sidebar: {
      type: Boolean,
    },
    tableLoading: {
      type: Boolean,
    },
    valores: {
      type: Array,
      default: () => [],
    },
    retornoApi: {
      type: Array,
      default: () => [],
    },
    total: {
      type: Number,
      default: 0,
    },
    tabelaEnvio: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    menu: false,
    menu1: false,
    menu2: false,
    valoresEditaveis: [],
    sidebarInterno: false,
  }),
  updated() {
    this.sidebarInterno = this.sidebar ? this.sidebar : false;
    this.valoresEditaveis = this.retornaEditavelPai(this.valores);
  },
  mounted() {
    this.sidebarInterno = this.sidebar ? this.sidebar : false;
  },
  methods: {
    retornaEditavelPai(pai) {
      return pai;
    },
    toggleSideBarPai() {
      if (this.sidebarInterno !== this.sidebar) {
        this.$emit('toggleSideBar', this.sidebarInterno);
      }
    },
    toggleSideBar() {
      this.sidebarInterno = !this.sidebarInterno;
      this.$emit('toggleSideBar', this.sidebarInterno);
    },
    clearFilters() {
      this.valoresEditaveis.forEach((coluna, colunaIndex) => {
        if (typeof coluna === 'object') {
          if (coluna) {
            coluna.forEach((data, dataIndex) => {
              this.valoresEditaveis[colunaIndex][dataIndex] = null;
            });
          }
        } else {
          this.valoresEditaveis[colunaIndex] = this.tabelaEnvio[colunaIndex].filtro.valor;
        }
      });
      this.$emit('clear');
    },
    apiSearch() {
      this.$emit('submitFilho', {
        valores: this.valoresEditaveis,
      });
    },
  },
};
</script>
