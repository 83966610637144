<template>
  <div class="text-center d-inline">
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <template v-slot:activator="{on, attrs}">
        <v-btn
          color="red lighten-2"
          dark
          x-small
          :icon="!!botaoText && botaoText.indexOf('mdi-') !== -1"
          v-bind="attrs"
          v-on="on"
        >
          <template v-if="botaoText">
            <template v-if="botaoText.indexOf('mdi-') !== -1">
              <v-icon dark>
                {{ botaoText }}
              </v-icon>
            </template>
            <template v-else>
              {{ botaoText }}
            </template>
          </template>
          <template v-else>
            {{ $t('leiaMais') }}
          </template>
          <!--          {{ botaoText ? botaoText : $t('leiaMais') }}-->
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{ title }}
        </v-card-title>
        <v-card-text>
          {{ text }}
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: 'WidgetTruncateLeiaMais',
  props: {
    title: {
      type: String,
      default: '',
    },
    botaoText: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
};
</script>
