<template>
  <div class="container--fluid fill-height">
    <PageTitulo :title="$t('route.complaints')" />
    <v-container>
      <PageExplicacao
        class="mb-5"
        :title="$t('denuncias.titulo')"
        :text="$t('denuncias.texto')"
      />
      <PageBuscaResultadoTabelaApi
        :results-per-page="[5,10,25,50,100]"
        url-api="denuncias"
        model="Denuncia"
        :api-authentication="true"
        :initial-search="true"
        :fetch-same-value-in-multiple-fields="fetchSameValueInMultipleFields"
        :table-title="camposBusca.tableTitle"
        :title-search="camposBusca.titleSearch"
        :tabela="camposBusca.tabela"
        :files-export="['pdf','xls','csv']"
        :actions="actions"
        :delectable="['Desenvolvedor', 'ICA', 'SDOP']"
        :editable="['Desenvolvedor', 'ICA', 'SDOP', 'AGA']"
      />
    </v-container>
  </div>
</template>
<!--fetchSameValueInMultipleFields-->
<script>
import PageTitulo from '@/views/common/widget/WidgetTitulo.vue';
import PageExplicacao from '@/views/common/widget/WidgetExplicacao.vue';
import PageBuscaResultadoTabelaApi from '@/views/common/widget/WidgetBuscaResultadoTabelaApi.vue';
import dataTable from './dataTable';
import actions from './actions';
import multipleFilter from './multipleFilter';

export default {
  name: 'Denuncias',
  components: {
    PageBuscaResultadoTabelaApi,
    PageExplicacao,
    PageTitulo,
  },
  data: () => ({
    rules: {
      required: (value) => !!value || 'Required.',
      max100: (value) => (value || '').length <= 100 || 'Max 100 characters',
      min4: (value) => (value && value.length >= 4) || 'Min 4 characters',
      min2: (value) => (value && value.length >= 2) || 'Min 2 characters',
    },
  }),
  computed: {
    actions() {
      return actions();
    },
    fetchSameValueInMultipleFields() {
      return multipleFilter();
    },

    camposBusca() {
      const retorno = {};
      retorno.tableTitle = this.$t('denuncias.resultados_busca');
      retorno.titleSearch = this.$t('denuncias.card.titulo');
      retorno.tabela = dataTable();
      return retorno;
    },
  },
  methods: {
  },

};
</script>
