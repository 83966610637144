<template>
  <div>
    <v-expansion-panels
      v-model="panel"
    >
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span class="text-h4">
            {{ title }}
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <p class="text-justify">
            {{ text }}
          </p>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
export default {
  name: 'WidgetExplicacao',
  props: {
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    panel: [0, 1],
  }),
};
</script>
