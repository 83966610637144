<template>
  <div>
    <!--    Componente que mostra filtros adicionais, -->
    <!--    apenas os que tiverem filtro.posicao === 'sidebar' -->
    <BuscaResultadoTabelaApiSideBar
      v-if="sidebar"
      :key="componentKeySearch * 10 + 1"
      :sidebar="sidebar"
      :fetch-same-value-in-multiple-fields="fetchSameValueInMultipleFields"
      :tabela-envio="tabelaEnvio"
      :total="total"
      :table-loading="tableLoading"
      :retorno-api="retornoApi"
      :valores="valores"
      :fetch-same-value-in-multiple-fields-value="fetchSameValueInMultipleFieldsValue"
      @clear="clear"
      @submitFilho="submitFilho"
      @toggleSideBar="toggleSideBar"
    />

    <!--    Componente que mostrar as colunas-->
    <!--    permitindo selecionar as colunas visiveis-->
    <!--    mudando visibleColumn de false para true-->
    <BuscaResultadoTabelaApiColunas
      v-if="sidebarColumns"
      :sidebar-columns="sidebarColumns"
      :tabela-envio="tabelaEnvio"
      @toggleSideBarColumns="toggleSideBarColumns"
      @visibleColumns="visibleColumns"
    />
    <v-card class="mb-5">
      <!--    Componente que mostra com filtro  'Busca'
      e filtros com filtro.posicao === 'central'
      Tem o mostrar guia, seleção de colunas e + filtros
       -->
      <BuscaResultadoTabelaApiBusca
        :key="componentKeySearch * 10 + 2"
        :sidebar="sidebar"
        :sidebar-columns="sidebarColumns"
        :fetch-same-value-in-multiple-fields="fetchSameValueInMultipleFields"
        :tabela-envio="tabelaEnvio"
        :total="total"
        :table-loading="tableLoading"
        :retorno-api="retornoApi"
        :valores="valores"
        :fetch-same-value-in-multiple-fields-value="fetchSameValueInMultipleFieldsValue"
        @clear="clear"
        @submitFilho="submitFilho"
        @toggleSideBar="toggleSideBar"
        @toggleSideBarColumns="toggleSideBarColumns"
        @showGuide="showGuide"
      />

      <!--      Tabela principal com colunas-->
      <!--      isColumn true-->
      <!--      visibleColumn true-->
      <!--      filtro.able true-->
      <!--      filtro.posicao coluna-->
      <v-data-table
        id="mainTable"
        :key="componentKeySearch * 10 + 3"
        :headers="tabela"
        :items="retornoApi"
        item-key="id"
        :items-per-page="1000"
        show-expand
        single-expand
        disable-sort
        dense
        class="elevation-1"
        :loading="tableLoading"
        :loading-text="$t('planos.resultado.table_loading')"
        hide-default-footer
        hide-default-header
        :expanded.sync="expanded"
      >
        <template v-slot:header.name="{header}">
          {{ header.text.toUpperCase() }}
        </template>
        <template v-slot:header>
          <tr>
            <th />
            <template v-for="(coluna, colunaIndex) in tabelaEnvio">
              <th
                v-if="coluna.isColumn && coluna.visibleColumn"
                :id="coluna.key"
                :key="colunaIndex"
              >
                {{ coluna.text.toUpperCase() }}

                <v-btn
                  v-if="coluna.orderable && !coluna.filho"
                  icon
                  color="indigo"
                  @click="changingOrder(coluna.key, order, sortby)"
                >
                  <v-icon>
                    <template v-if="coluna.key !== sortby">
                      mdi-sort
                    </template>
                    <template v-else>
                      {{ order === 'asc' ? 'mdi-sort-ascending' : 'mdi-sort-descending' }}
                    </template>
                  </v-icon>
                </v-btn>

                <template v-if="coluna.filtro.able && coluna.filtro.posicao === 'coluna'">
                  <div v-if="coluna.filtro.type === 'string'">
                    <v-text-field
                      v-model="valores[colunaIndex]"
                      dense
                      :rules="coluna.filtro.rules"
                      class="mx-5 my-0"
                      @keyup.enter="apiSearch(fetchSameValueInMultipleFields,
                                              fetchSameValueInMultipleFieldsValue,
                                              valores,
                                              tabelaEnvio,
                                              quantidade,
                                              1,
                                              order,
                                              sortby)"
                    />
                  </div>
                  <div v-if="coluna.filtro.type === 'select'">
                    <v-autocomplete
                      v-model="valores[colunaIndex]"
                      :items="coluna.filtro.items"
                      :multiple="coluna.filtro.multiple"
                      item-text="texto"
                      item-value="id"
                      class="mx-5 my-0"
                    />
                  </div>
                  <div v-if="coluna.filtro.type === 'date'">
                    <v-menu
                      v-model="menu1"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                      class="mx-5 my-0"
                    >
                      <template v-slot:activator="{on, attrs}">
                        <v-text-field
                          v-model="valores[colunaIndex][0]"
                          dense
                          :placeholder="`inicial`"
                          readonly
                          v-bind="attrs"
                          class="mx-5 mb-0 mt-1"
                          v-on="on"
                        />
                      </template>
                      <v-date-picker
                        v-model="valores[colunaIndex][0]"
                        @input="menu1 = false"
                      />
                    </v-menu>
                    <v-menu
                      v-model="menu2"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                      class="mx-5 my-0"
                    >
                      <template v-slot:activator="{on, attrs}">
                        <v-text-field
                          v-model="valores[colunaIndex][1]"
                          dense
                          :placeholder="`final`"
                          readonly
                          v-bind="attrs"
                          class="mx-5 my-0"
                          v-on="on"
                        />
                      </template>
                      <v-date-picker
                        v-model="valores[colunaIndex][1]"
                        @input="menu2 = false"
                      />
                    </v-menu>
                  </div>
                </template>
              </th>
            </template>
            <!--          linha do header da tabela-->
            <th
              v-if="actions.length > 0"
              id="actionColumn"
              class="text-subtitle-2 mr-2 align-content-lg-stretch"
            >
              {{ $t('actions').toUpperCase() }}
            </th>
          </tr>
        </template>
        <template v-slot:body="{items}">
          <tbody
            v-for="(item, itemIndex) in items"
            :key="itemIndex"
          >
            <tr>
              <td />
              <template v-for="(coluna, colunaIndex) in tabelaEnvio">
                <td
                  v-if="coluna.isColumn && coluna.visibleColumn"
                  :key="coluna.text + '_0_' + colunaIndex"
                  :class="coluna.rowsClasse"
                >
                  <!--                  campos da tabela-->
                  <!--                  podem ter link   dataTable.link-->
                  <!--                  os tipos são  ['string','date','object',select]-->
                  <!--                  dataTable.type-->
                  <template v-if="coluna.value && item[coluna.value]">
                    <template v-if="coluna.link">
                      <!--                      quando há mais de 100 caractes no campo-->
                      <!--                      é truncado e aparece icon com leia mais-->
                      <template v-if="coluna.type === 'string'">
                        <!-- eslint-disable-next-line max-len -->
                        <a :href="`${apiSysAGAAntigo}/${coluna.link.estatico}${ item[coluna.link.key] }`">
                          {{ item[coluna.value] | truncate(100) }}
                        </a>
                        <widget-truncate-leia-mais
                          v-if="item[coluna.value].length > 100"
                          :title="coluna.text"
                          :text="item[coluna.value]"
                          botao-text="mdi-arrow-expand"
                        />
                      </template>
                      <template v-if="coluna.type === 'date'">
                        <!-- eslint-disable-next-line max-len -->
                        <a :href="`${apiSysAGAAntigo}/${coluna.link.estatico}${ item[coluna.link.key] }`">
                          {{ item[coluna.value] | moment('DD-MM-YY') }}
                        </a>
                      </template>
                      <!--                      quanto o tipo é object-->
                      <!--                      é preciso ter o valor filho-->
                      <!--                      exemplo  processo.nup  -->
                      <!--                      value  -> processo-->
                      <!--                      filho  -> nup-->

                      <template v-if="coluna.type === 'object'">
                        <!-- eslint-disable-next-line max-len -->
                        <a :href="`${apiSysAGAAntigo}/${coluna.link.estatico}${ item[coluna.link.key] }`">
                          {{ item[coluna.value][coluna.filho] | truncate(100) }}
                          <widget-truncate-leia-mais
                            v-if="item[coluna.value][coluna.filho].length > 100"
                            class="d-inline"
                            :title="coluna.text"
                            :text="item[coluna.value][coluna.filho]"
                            botao-text="mdi-arrow-expand"
                          />
                        </a>
                      </template>
                      <!-- eslint-disable-next-line max-len -->
                      <!--                      quando coluna type === 'select'-->
                      <!--                      as opcoes vem de coluna.opcoes-->

                      <template
                        v-if="coluna.type === 'select' &&
                          item[coluna.value] &&
                          coluna.opcoes[item[coluna.value]]"
                      >
                        <!-- eslint-disable-next-line max-len -->
                        <a :href="`${apiSysAGAAntigo}/${coluna.link.estatico}${ item[coluna.link.key] }`">
                          {{ coluna.opcoes[item[coluna.value]].texto }}
                        </a>
                      </template>
                    </template>
                    <template v-else>
                      <template v-if="coluna.type === 'string'">
                        {{ item[coluna.value] | truncate(100) }}
                        <widget-truncate-leia-mais
                          v-if="item[coluna.value].length > 100"
                          class="d-inline"
                          :title="coluna.text"
                          :text="item[coluna.value]"
                          botao-text="mdi-arrow-expand"
                        />
                      </template>
                      <template v-if="coluna.type === 'date'">
                        {{ item[coluna.value] | moment('DD-MM-YY') }}
                      </template>
                      <template v-if="coluna.type === 'object'">
                        {{ item[coluna.value][coluna.filho] | truncate(100) }}
                        <widget-truncate-leia-mais
                          v-if="item[coluna.value][coluna.filho].length > 100"
                          class="d-inline"
                          :title="coluna.text"
                          :text="item[coluna.value][coluna.filho]"
                          botao-text="mdi-arrow-expand"
                        />
                      </template>
                      <!-- eslint-disable-next-line max-len -->
                      <template
                        v-if="coluna.type === 'select' &&
                          item[coluna.value] &&
                          coluna.opcoes[item[coluna.value]]"
                      >
                        {{ coluna.opcoes[item[coluna.value]].texto }}
                      </template>
                    </template>
                    <!--                    Se o tipo for 'array'-->
                    <!--                    e colunaComLength === true-->
                    <!--                    é aparece a opção de expandir linha-->

                    <template v-if="coluna.type === 'array' && coluna.colunaComLength">
                      <v-chip
                        color="blue"
                        x-small
                        dark
                        class="ml-3"
                        @click="clicked(item.expandedShow = !item.expandedShow)"
                      >
                        <span class="mr-2">
                          {{ item[coluna.value].length }}
                        </span>
                        <v-icon
                          v-if="item.expandedShow"
                          dark
                        >
                          mdi-chevron-down
                        </v-icon>
                        <v-icon
                          v-if="!item.expandedShow"
                          dark
                        >
                          mdi-chevron-up
                        </v-icon>
                      </v-chip>
                    </template>
                  </template>
                </td>
              </template>
              <!--              as ações são listadas abaixo se ajustando a cada linha-->
              <!--              em actions.conditional há condições se a ação aparece em-->
              <!--              função dos dados da linha-->
              <!--              exemplo-->
              <!--              conditional: [{ key: 'regional', value: 4 }],-->
              <!--              só aparece nas linhas que o regional === 4-->
              <!--              as ações tb se ajustam ao role de quem tá logado (action.can)-->
              <!--              é seguro já que é feito outro teste no back-->
              <td
                v-if="actions.length > 0"
                class="text-subtitle-2 mr-2"
              >
                <template v-for="(action, actionIndex) in actions">
                  <v-tooltip
                    v-if="action.can && conditionalItem(item,action)"
                    :key="actionIndex"
                    bottom
                  >
                    <template v-slot:activator="{on, attrs}">
                      <v-btn
                        class="py-4 mx-1"
                        :icon="!!action.icon"
                        :color="action.color"
                        v-bind="attrs"
                        x-small
                        v-on="on"
                        @click="actionApi(action,
                                          item[action.key])"
                      >
                        <template v-if="action.icon">
                          <v-icon>
                            {{ action.icon }}
                          </v-icon>
                        </template>
                        <template v-else>
                          {{ action.text }}
                        </template>
                      </v-btn>
                    </template>
                    <span>
                      {{ action.explanation }}
                    </span>
                  </v-tooltip>
                </template>
              </td>
            </tr>
            <tr v-if="item.expandedShow">
              <td
                :colspan="+tabelaEnvio.length + 1"
                class="elevation-3  lighten-4 pb-2"
              >
                <v-row
                  class="mx-0 my-0"
                  align="center"
                  justify="center"
                >
                  <template v-for="(coluna, colunaIndex) in tabelaEnvio">
                    <v-col
                      v-if="coluna.expanded && coluna.value && item[coluna.value]"
                      :key="coluna.text + '_0_' + colunaIndex"
                      cols="12"
                      sm="6"
                      class="my-0 py-0"
                    >
                      <v-card class="mx-auto my-2">
                        <v-card-text>
                          <p class="font-weight-medium text--primary">
                            {{ coluna.text }}
                          </p>
                          <div
                            v-if="coluna.type === 'array' "
                            class="text--primary"
                          >
                            <template v-if="coluna.expanded.type === 'list'">
                              <ul
                                v-for="(filho, index) in
                                  orderBy(item[coluna.value], coluna.expanded.itensFor[0])"
                                :key="index"
                              >
                                <li :key="index">
                                  <span
                                    v-for="(colunasFilho, colunasFilhoIndex) in
                                      coluna.expanded.itensFor.slice().reverse()"
                                    :key="colunasFilhoIndex + '_0'"
                                  >
                                    {{ filho[colunasFilho] }}
                                    <!-- eslint-disable-next-line max-len -->
                                    <template v-if="colunasFilhoIndex !== coluna.expanded.itensFor.length - 1 ">
                                      {{ coluna.expanded.separadorItens }}
                                    </template>
                                  </span>
                                </li>
                              </ul>
                            </template>
                            <template v-if="coluna.expanded.type === 'chip'">
                              <div class="text--primary">
                                <v-row
                                  class="mb-5 mt-5"
                                  align="center"
                                >
                                  <v-col
                                    v-for="(filho) in
                                      orderBy(item[coluna.value], coluna.expanded.itensFor[0])"
                                    :key="filho.id"
                                    cols="6"
                                    md="4"
                                    lg="3"
                                    class="my-0 py-0"
                                  >
                                    <!-- eslint-disable max-len -->
                                    <a :href="`${apiSysAGAAntigo}/${coluna.expanded.link.estatico}${ filho[coluna.expanded.link.key] }`">
                                      <template v-if="coluna.expanded.classeCondicionada ">

                                        <!-- eslint-disable max-len -->
                                        <v-chip
                                          dark
                                          x-small
                                          :class="
                                            filho[coluna.expanded.classeCondicionada.item].toUpperCase()
                                              !==
                                              coluna.expanded.classeCondicionada.valor ?
                                                coluna.expanded.classeCondicionada.recebeClasseIgual :
                                                coluna.expanded.classeCondicionada.recebeClasseDiferente "
                                        >
                                          <span
                                            v-for="(colunasFilho, colunasFilhoIndex) in
                                              coluna.expanded.itensFor.slice().reverse()"
                                            :key="colunasFilhoIndex + '_0'"
                                          >
                                            {{ filho[colunasFilho].toUpperCase() }}
                                            <!-- eslint-disable-next-line max-len -->
                                            <template
                                              v-if="colunasFilhoIndex !== coluna.expanded.itensFor.length - 1 "
                                            >
                                              {{ coluna.expanded.separadorItens }}
                                            </template>
                                          </span>
                                        </v-chip>
                                      </template>
                                      <template v-else>
                                        <v-chip
                                          dark
                                          x-small
                                        >
                                          <span
                                            v-for="(colunasFilho, colunasFilhoIndex) in
                                              coluna.expanded.itensFor.slice().reverse()"
                                            :key="colunasFilhoIndex + '_0'"
                                          >
                                            {{ filho[colunasFilho].toUpperCase() }}
                                            <!-- eslint-disable-next-line max-len -->
                                            <template
                                              v-if="colunasFilhoIndex !== coluna.expanded.itensFor.length - 1 "
                                            >
                                              {{ coluna.expanded.separadorItens }}
                                            </template>
                                          </span>
                                        </v-chip>
                                      </template>
                                    </a>
                                  </v-col>
                                </v-row>
                              </div>
                            </template>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </template>
                </v-row>
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
      <v-container class="indigo lighten-4">
        <v-row
          no-gutters
          justify="space-between"
          align="center"
        >
          <v-col
            id="exportFileCol"
            cols="auto"
          >
            <!--            foreach das opções de export-->
            <!--            :files-export="['pdf']"-->
            <v-icon v-if="waitingApiExportxls">
              mdi-loading
            </v-icon>
            <template
              v-for="(fileExport, fileExportIndex) in filesExport"
            >
              <v-btn
                v-if="!['xls','csv'].includes(fileExport)"
                :key="fileExportIndex * 100 + 1"
                x-small
                class="py-4 mx-1 orange lighten-4"
                @click="exportFile(fetchSameValueInMultipleFields,
                                   fetchSameValueInMultipleFieldsValue,
                                   valores,
                                   tabelaEnvio,
                                   fileExport,
                                   order,
                                   sortby)"
              >
                {{ fileExport }}
              </v-btn>
              <!--              https://www.npmjs.com/package/vue-json-excel-->

              <download-excel
                v-if="['xls','csv'].includes(fileExport) && !waitingApiExportxls"
                :key="fileExportIndex * 100 + 2"
                :fetch="fetchData"
                :fields="jsonFieldsToExport"
                :type="fileExport"
                :name="`${model}.${fileExport}`"
                :before-generate="startDownload"
                :before-finish="finishDownload"
              >
                <v-btn
                  x-small
                  class="py-4 mx-1 orange lighten-4"
                >
                  {{ fileExport.toUpperCase() }}
                </v-btn>
              </download-excel>
            </template>
          </v-col>
          <v-col
            v-if="retornoApi.length > 0 && quantitiesPerPage !== 1"
            cols="auto"
          >
            <v-pagination
              id="pagination"
              v-model="page"
              :disabled="tableLoading"
              class="my-1"
              :total-visible="totalVisible"
              :length="quantitiesPerPage"
              @input="apiSearch(fetchSameValueInMultipleFields,
                                fetchSameValueInMultipleFieldsValue,
                                valores, tabelaEnvio,
                                quantidade,
                                page,
                                order,
                                sortby)"
            />
          </v-col>
          <v-col
            cols="4"
            sm="4"
            md="3"
            lg="2"
          >
            <v-select
              v-if="total >= 5"
              id="resultsPerPage"
              v-model="quantidade"
              :items="resultsPerPage"
              label="Quantidade por página"
            />
          </v-col>
        </v-row>
      </v-container>
      <!--componente que aparece quand as ações precisam de confirmação-->
      <!--      confirmationRequired: true,-->

      <BuscaResultadoTabelaApiDialogActionConfirmation
        v-if="dialogActionConfirmation"
        :key="componentKeySearch * 10 + 4"
        :text-dialog-action-confirmation="textDialogActionConfirmation"
        :explanation-dialog-action-confirmation="explanationDialogActionConfirmation"
        :id-dialog-action-confirmation="idDialogActionConfirmation"
        :type-dialog-action-confirmation="typeDialogActionConfirmation"
        @returnConfirmationAction="returnConfirmationAction"
      />
    </v-card>
  </div>
</template>

<script>
// https://github.com/kamranahmedse/driver.js
import Driver from 'driver.js'; // import driver.js
import 'driver.js/dist/driver.min.css'; // import driver.js css
import router from '@/router';
// https://www.npmjs.com/package/vue2-filters
import Vue2Filters from 'vue2-filters';
// https://www.npmjs.com/package/vue-json-excel
import JsonExcel from '../../../../node_modules/vue-json-excel/JsonExcel.vue';
import WidgetTruncateLeiaMais from './WidgetTruncateLeiaMais.vue';
import BuscaResultadoTabelaApiBusca from './buscaResultadoTabelaApi/BuscaResultadoTabelaApiBusca.vue';
import BuscaResultadoTabelaApiSideBar
  from './buscaResultadoTabelaApi/BuscaResultadoTabelaApiSideBar.vue';
import BuscaResultadoTabelaApiColunas
  from './buscaResultadoTabelaApi/BuscaResultadoTabelaApiColunas.vue';
import BuscaResultadoTabelaApiDialogActionConfirmation
  from './buscaResultadoTabelaApi/BuscaResultadoTabelaApiDialogActionConfirmation.vue';
import stepsBeforeColumns from './buscaResultadoTabelaApi/stepsBeforeColumns';
import stepsAfterColumns from './buscaResultadoTabelaApi/stepsAfterColumns';

export default {
  name: 'WidgetBuscaResultadoTabelaApi',
  components: {
    BuscaResultadoTabelaApiSideBar,
    WidgetTruncateLeiaMais,
    BuscaResultadoTabelaApiBusca,
    BuscaResultadoTabelaApiColunas,
    BuscaResultadoTabelaApiDialogActionConfirmation,
    downloadExcel: JsonExcel,
  },
  mixins: [Vue2Filters.mixin],
  props: {
    apiAuthentication: {
      type: Boolean,
    },
    initialSearch: {
      type: Boolean,
    },
    fetchSameValueInMultipleFields: {
      type: Array,
      default: () => [],
    },
    actions: {
      type: Array,
      default: () => [],
    },
    urlApi: {
      type: String,
      default: '',
    },
    model: {
      type: String,
      default: '',
    },
    tableTitle: {
      type: String,
      default: '',
    },
    titleSearch: {
      type: String,
      default: '',
    },
    tabela: {
      type: Array,
      default: () => [],
    },
    filesExport: {
      type: Array,
      default: () => [],
    },
    selectable: {
      type: Array,
      default: () => [],
    },
    editable: {
      type: Array,
      default: () => [],
    },
    resultsPerPage: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    jsonFieldsToExport: {},
    waitingApiExportxls: false,
    returnFullApi: [],
    driver: null, // Guide
    dialogActionConfirmation: false, // modal da ação p confirmar
    textDialogActionConfirmation: '',
    explanationDialogActionConfirmation: '',
    idDialogActionConfirmation: '',
    typeDialogActionConfirmation: '',
    order: 'asc',
    sortby: '',
    roles: [],
    permissions: [],
    componentKeySearch: 0,
    fetchSameValueInMultipleFieldsValue: '',
    date: new Date().toISOString().substr(0, 10),
    sidebar: false,
    sidebarColumns: false,
    menu: false,
    modal: false,
    menu1: false,
    menu2: false,
    dataTeste: [],
    tableLoading: false,
    apiSysAGANovo: '',
    apiSysAGAAntigo: '',
    expanded: [],
    retornoApi: [],
    total: null,
    perPage: null,
    quantitiesPerPage: 1,
    totalVisible: 7,
    from: null,
    to: null,
    page: 1,
    quantidade: 5,
    search: '',
    valores: [],
  }),
  computed: {
    tabelaEnvio() {
      return this.tabela;
    },
  },
  created() {
    this.tabelaEnvio.forEach((coluna, colunaIndex) => {
      this.valores[colunaIndex] = coluna.filtro.valor;
      if (coluna.isColumn && coluna.visibleColumn && !coluna.filho) {
        this.jsonFieldsToExport[coluna.text] = coluna.value;
      }
      if (coluna.isColumn && coluna.visibleColumn && coluna.filho) {
        this.jsonFieldsToExport[coluna.text] = `${coluna.value}.${coluna.filho}`;
      }
    });
  },
  mounted() {
    this.driver = new Driver({
      doneBtnText: this.$t('doneBtnText'), // Text on the final button
      closeBtnText: this.$t('closeBtnText'), // Text on the close button for this step
      nextBtnText: this.$t('nextBtnText'), // Next button text for this step
      prevBtnText: this.$t('prevBtnText'), // Previous button text for this step
      animate: false,
    }); // Não habilite a animação. Referência: https://github.com/kamranahmedse/driver.js/issues/31
    this.apiSysAGANovo = process.env.VUE_APP_URL_API;
    this.apiSysAGAAntigo = process.env.VUE_APP_URL_SysAGA2;
    if (this.initialSearch) {
      this.apiPreferenciasusuarios();
      this.apiSearch(
        this.fetchSameValueInMultipleFields,
        this.fetchSameValueInMultipleFieldsValue,
        this.valores,
        this.tabelaEnvio,
        this.quantidade,
        1,
        this.order,
        this.sortby,
      );
    }
  },
  methods: {
    showGuide() {
      const guidesTable = [];

      this.tabelaEnvio.forEach((coluna) => {
        if (coluna.key && coluna.isColumn && coluna.visibleColumn) {
          guidesTable.push(
            {
              element: `#${coluna.key}`,
              popover: {
                title: coluna.text,
                description: coluna.explicacao,
                position: 'botton',
              },
            },
          );
        }
      });
      console.log('guidesTable');
      console.log(guidesTable);
      this.driver.defineSteps([...stepsBeforeColumns(), ...guidesTable, ...stepsAfterColumns()]);
      this.driver.start();
    },
    conditionalItem(item, action) {
      let result = true;
      if (action.conditional.length > 0) {
        /*  eslint-disable max-len  */
        if (action.conditional.some((condition) => item[condition.key] && item[condition.key] !== condition.value)) {
          result = false;
        }
      }
      return result;
    },
    actionApiSendAfterConfirmated(type, id) {
      const apiurl = `${process.env.VUE_APP_URL_API}/${this.urlApi}`;
      this.$axios.post(`${apiurl}/actions`, {
        type, id,
      }, {
        headers: { Authorization: `Bearer ${router.app.$keycloak.token}` },
      })
        .then((response) => {
          console.log('response');
          console.log(response);
        }).catch(console.error);
      this.dialogActionConfirmation = false;
      this.textDialogActionConfirmation = '';
      this.explanationDialogActionConfirmation = '';
      this.idDialogActionConfirmation = '';
      this.typeDialogActionConfirmation = '';
    },
    returnConfirmationAction(event) {
      this.actionApiSendAfterConfirmated(event.type, event.id);
    },
    actionApi(action, id) {
      if (action.cause === 'axios') {
        if (action.confirmationRequired) {
          this.componentKeySearch += 1;
          this.textDialogActionConfirmation = action.text;
          this.explanationDialogActionConfirmation = action.explanation;
          this.idDialogActionConfirmation = id;
          this.typeDialogActionConfirmation = action.type;
          this.dialogActionConfirmation = true;
        } else {
          this.actionApiSendAfterConfirmated(action.type, id);
        }
      } else {
        window.location.href = `${action.url}/${action.key}`;
      }
    },
    changingOrder(key, order, sortby) {
      if (key === sortby) {
        this.order = order === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortby = key;
        this.order = 'asc';
      }
      this.apiSearch(
        this.fetchSameValueInMultipleFields,
        this.fetchSameValueInMultipleFieldsValue,
        this.valores,
        this.tabelaEnvio,
        this.quantidade,
        1,
        this.order,
        this.sortby,
      );
    },
    visibleColumns(listaColunas) {
      if (listaColunas.length > 0) {
        listaColunas.forEach((coluna, indexColuna) => {
          this.tabelaEnvio[indexColuna].visibleColumn = coluna;
        });
      }
      this.apiSalvarPreferenciasusuarios();
      this.componentKeySearch += 1;
    },
    toggleSideBarColumns(event) {
      this.sidebarColumns = !!event;
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
      this.componentKeySearch += 1;
    },
    toggleSideBar(event) {
      this.sidebar = !!event;
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
      this.componentKeySearch += 1;
    },
    submitFilho(event) {
      if (event.fetchSameValueInMultipleFieldsValue !== undefined) {
        this.fetchSameValueInMultipleFieldsValue = event.fetchSameValueInMultipleFieldsValue;
      }
      this.valores = event.valores;
      this.apiSearch(
        this.fetchSameValueInMultipleFields,
        this.fetchSameValueInMultipleFieldsValue,
        this.valores,
        this.tabelaEnvio,
        this.quantidade,
        1,
        this.order,
        this.sortby,
      );
    },
    clear() {
      this.clearFilters();
    },
    // https://www.npmjs.com/package/vue-json-excel
    startDownload() {
      this.waitingApiExportxls = true;
    },
    finishDownload() {
      this.waitingApiExportxls = false;
    },

    // https://www.npmjs.com/package/vue-json-excel
    async fetchData() {
      const apiurl = `${process.env.VUE_APP_URL_API}/${this.urlApi}`;
      const response = await this.$axios.post(`${apiurl}/actions`, {
        fetchSameValueInMultipleFields: this.fetchSameValueInMultipleFields,
        fetchSameValueInMultipleFieldsValue: this.fetchSameValueInMultipleFieldsValue,
        valores: this.valores,
        tabela: this.tabela,
        order: this.order,
        sortby: this.sortby,
        type: 'json',
      }, {
        headers: { Authorization: `Bearer ${router.app.$keycloak.token}` },
      });
      console.log(response);
      console.log('response.data.dados');
      console.log(response.data.dados);

      return response.data.dados;
    },

    /*  eslint-disable max-len  */
    exportFile(fetchSameValueInMultipleFields, fetchSameValueInMultipleFieldsValue, valores, tabela, tipo, order, sortby) {
      const apiurl = `${process.env.VUE_APP_URL_API}/${this.urlApi}`;
      this.$axios.post(`${apiurl}/actions`, {
        fetchSameValueInMultipleFields,
        fetchSameValueInMultipleFieldsValue,
        valores,
        tabela,
        order,
        sortby,
        type: tipo,
      }, {
        responseType: 'blob', headers: { Authorization: `Bearer ${router.app.$keycloak.token}` },
      })
        .then((response) => {
          const blob = new Blob([response.data], { type: `application/${tipo}` });
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = tipo;
          link.click();
          URL.revokeObjectURL(link.href);
        }).catch(console.error);
    },
    clicked(id) {
      if (this.expanded && this.expanded[0] && this.expanded[0].id === id) {
        this.expanded = [];
      } else {
        const achandoLinha = this.planos.filter((cada) => cada.id === id);
        this.expanded = achandoLinha;
      }
    },
    clearFilters() {
      /* eslint-disable */
      this.valores.forEach((coluna,colunaIndex) => {
        if(typeof coluna === 'object'){
          if(coluna){
            coluna.forEach((data,dataIndex) => {
              this.valores[colunaIndex][dataIndex] = null;
            });
          }
        }else{
          this.valores[colunaIndex] = this.tabela[colunaIndex].filtro.valor;
        }
      });
      this.apiSearch(
        this.fetchSameValueInMultipleFields,
        this.fetchSameValueInMultipleFieldsValue,
        this.valores,
        this.tabelaEnvio,
        this.quantidade,
        1,
        this.order,
        this.sortby,
      );
    },
    async apiSearch(fetchSameValueInMultipleFields,
      fetchSameValueInMultipleFieldsValue,
      valores,
      tabela,
      quantidade,
      page,
      order,
      sortby) {
      this.tableLoading = true;
      const apiurl = `${process.env.VUE_APP_URL_API}/${this.urlApi}`;

      if (this.apiAuthentication) {
        try {
          await this.$axios.post(`${apiurl}?page=${page}`,
            {fetchSameValueInMultipleFields,  fetchSameValueInMultipleFieldsValue, valores, tabela, quantidade, order, sortby },
            { headers: { Authorization: `Bearer ${router.app.$keycloak.token}` } })
            .then((response) => {
              const retornoApi = response.data.dados;
              const roles = response.data.roles;
              this.roles = roles;

              if(this.roles && this.roles.length > 0){
                this.actions.forEach((action, actionIndex) =>{
                  if(this.roles.some((role)=>{
                    return action.roles.includes(role);
                  })
                  ){
                    this.actions[actionIndex].can=true;
                  }
                });
              }
              this.total = retornoApi.total;
              this.perPage = retornoApi.per_page;
              this.from = retornoApi.from;
              this.to = retornoApi.to;
              this.page = retornoApi.current_page;
              this.retornoApi = retornoApi.data;
              this.tableLoading = false;
              this.quantitiesPerPage = Math.ceil(this.total / this.perPage);
              this.totalVisible = this.quantitiesPerPage;
              if (this.quantitiesPerPage > 7) {
                this.totalVisible = 7;
              }
              this.componentKeySearch += 1;
            })
            .catch((error) => {
              console.log('getLogs', error);
            });
        } catch (err) {
          console.warn('getLogs', err);
        }
      } else {
        await this.$axios.post(`${apiurl}?page=${page}`, {
          tabela,
          quantidade,
        })
          .then((response) => {
            const retornoApi = response.data.downloads;
            this.total = retornoApi.total;
            this.perPage = retornoApi.per_page;
            this.from = retornoApi.from;
            this.to = retornoApi.to;
            this.page = retornoApi.current_page;
            this.retornoApi = retornoApi.data;
            this.tableLoading = false;
            this.quantitiesPerPage = Math.ceil(this.total / this.perPage);
            this.totalVisible = this.quantitiesPerPage;
            if (this.quantitiesPerPage > 7) {
              this.totalVisible = 7;
            }
            this.componentKeySearch += 1;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    async apiSalvarPreferenciasusuarios() {
      const apiurl = `${process.env.VUE_APP_URL_API}/preferencias_colunas_salvar`;
        try {
          await this.$axios.post(apiurl,
            {nomeTabela: this.model, tabela: this.tabelaEnvio },
            { headers: { Authorization: `Bearer ${router.app.$keycloak.token}` } })
            .then((response) => {
              const retornoApi = response.data;
              console.log(retornoApi);
            })
            .catch((error) => {
              console.log('getLogs', error);
            });
        } catch (err) {
          console.warn('getLogs', err);
        }
    },
    async apiPreferenciasusuarios() {
      const apiurl = `${process.env.VUE_APP_URL_API}/preferencias_colunas`;
        try {
          await this.$axios.post(apiurl,
            {nomeTabela: this.model },
            { headers: { Authorization: `Bearer ${router.app.$keycloak.token}` } })
            .then((response) => {
              const preferencias = response.data.dados;
              if(preferencias.length > 0){
                this.tabelaEnvio.forEach((coluna, colunaIndex) => {
                  this.tabelaEnvio[colunaIndex].visibleColumn = false;
                  if (preferencias.includes(coluna.key)) {
                    this.tabelaEnvio[colunaIndex].visibleColumn = true;
                  }
                });
                this.componentKeySearch += 1;
              }
            })
            .catch((error) => {
              console.log('getLogs', error);
            });
        } catch (err) {
          console.warn('getLogs', err);
        }
    },
  },
};
</script>
