<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <!--      @input="dialog ? '' : $destroy()"-->
      <template v-slot:activator="{on, attrs}">
        <v-btn
          color="red lighten-2"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Click Me
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{ textDialogActionConfirmation }}
        </v-card-title>
        <v-card-text>
          {{ explanationDialogActionConfirmation }}
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="returnConfirmationAction()"
          >
            {{ $t('Confirmo') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'BuscaResultadoTabelaApiDialogActionConfirmation',
  props: {
    textDialogActionConfirmation: {
      type: String,
      default: '',
    },
    explanationDialogActionConfirmation: {
      type: String,
      default: '',
    },
    idDialogActionConfirmation: {
      type: Number,
      default: 0,
    },
    typeDialogActionConfirmation: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dialog: true,
    };
  },
  created() {
    this.dialogWatcher = this.$watch('dialog', (newVal) => {
      if (!newVal) {
        // this.$destroy();
        console.log(newVal);
      }
    });
  },
  methods: {
    returnConfirmationAction() {
      this.$emit('returnConfirmationAction', {
        id: this.idDialogActionConfirmation,
        type: this.typeDialogActionConfirmation,
      });
      // this.$destroy();
    },
  },
};
</script>
