var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card-title',[_c('v-hover',{attrs:{"open-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"elevation":hover ? 16 : 2,"width":"100%"}},[_c('v-row',{attrs:{"align":"baseline","justify":"center"}},[(_vm.fetchSameValueInMultipleFields.length > 0)?_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":11,"sm":6,"md":5,"lg":4}},[_c('v-row',{attrs:{"align":"baseline","justify":"center"}},[_c('v-col',[_c('v-text-field',{attrs:{"id":"fetchSameValueInMultipleFieldsValueEditable","dense":"","label":_vm.$t('fetchSameValueInMultipleFieldsValue')},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.apiSearch()}},model:{value:(_vm.fetchSameValueInMultipleFieldsValueEditable),callback:function ($$v) {_vm.fetchSameValueInMultipleFieldsValueEditable=$$v},expression:"fetchSameValueInMultipleFieldsValueEditable"}})],1),_c('v-col',{staticClass:"mr-5",attrs:{"cols":"auto"}},[_c('widget-truncate-leia-mais',{attrs:{"title":_vm.$t('lista_campos_busca'),"text":_vm.fetchSameValueInMultipleFieldsJoinText,"botao-text":"mdi-information-outline"}})],1)],1)],1):_vm._e(),_vm._l((_vm.tabelaEnvio),function(coluna,colunaIndex){return [(!coluna.isColumn &&
              coluna.filtro.able &&
              coluna.filtro.posicao === 'central' &&
              coluna.filtro.type === 'string')?_c('v-col',{key:coluna.item,class:coluna.filtro.column.classe,attrs:{"cols":coluna.filtro.column.cols,"sm":coluna.filtro.column.sm,"lg":coluna.filtro.column.lg}},[_c('v-text-field',{attrs:{"dense":"","rules":coluna.filtro.rules,"label":coluna.filtro.label},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.apiSearch()}},model:{value:(_vm.valoresEditaveis[colunaIndex]),callback:function ($$v) {_vm.$set(_vm.valoresEditaveis, colunaIndex, $$v)},expression:"valoresEditaveis[colunaIndex]"}})],1):_vm._e(),(!coluna.isColumn &&
              coluna.filtro.able &&
              coluna.filtro.posicao === 'central' &&
              coluna.filtro.type === 'select')?_c('v-col',{key:coluna.item,class:coluna.filtro.column.classe,attrs:{"cols":coluna.filtro.column.cols,"sm":coluna.filtro.column.sm,"lg":coluna.filtro.column.lg}},[_c('v-autocomplete',{attrs:{"items":coluna.filtro.items,"label":coluna.filtro.label,"multiple":coluna.filtro.multiple,"item-text":"texto","item-value":"id"},model:{value:(_vm.valoresEditaveis[colunaIndex]),callback:function ($$v) {_vm.$set(_vm.valoresEditaveis, colunaIndex, $$v)},expression:"valoresEditaveis[colunaIndex]"}})],1):_vm._e(),(!coluna.isColumn &&
              coluna.filtro.able &&
              coluna.filtro.posicao === 'central' &&
              coluna.filtro.type === 'date')?_c('v-col',{key:coluna.item,class:coluna.filtro.column.classe,attrs:{"cols":coluna.filtro.column.cols,"sm":coluna.filtro.column.sm,"lg":coluna.filtro.column.lg}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mx-5 mb-0 mt-1",attrs:{"dense":"","label":((coluna.filtro.label) + " inicial"),"readonly":""},model:{value:(_vm.valoresEditaveis[colunaIndex][0]),callback:function ($$v) {_vm.$set(_vm.valoresEditaveis[colunaIndex], 0, $$v)},expression:"valoresEditaveis[colunaIndex][0]"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menu1 = false}},model:{value:(_vm.valoresEditaveis[colunaIndex][0]),callback:function ($$v) {_vm.$set(_vm.valoresEditaveis[colunaIndex], 0, $$v)},expression:"valoresEditaveis[colunaIndex][0]"}})],1),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mx-5 my-0",attrs:{"dense":"","label":((coluna.filtro.label) + " final"),"readonly":""},model:{value:(_vm.valoresEditaveis[colunaIndex][1]),callback:function ($$v) {_vm.$set(_vm.valoresEditaveis[colunaIndex], 1, $$v)},expression:"valoresEditaveis[colunaIndex][1]"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.valoresEditaveis[colunaIndex][1]),callback:function ($$v) {_vm.$set(_vm.valoresEditaveis[colunaIndex], 1, $$v)},expression:"valoresEditaveis[colunaIndex][1]"}})],1)],1):_vm._e()]}),_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"py-4 mx-1 red lighten-4",attrs:{"id":"clearFilters","x-small":"","disabled":_vm.tableLoading},on:{"click":function($event){return _vm.clearFilters()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-filter-remove ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('clearFilters'))+" ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"py-4 mx-1 primary",attrs:{"id":"search","x-small":"","disabled":_vm.tableLoading},on:{"click":function($event){return _vm.apiSearch()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-magnify ")]),(_vm.retornoApi.length > 0)?_c('span',{staticClass:"ml-2"},[_vm._v(" ("+_vm._s(_vm.total)+") ")]):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('button.search'))+" ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"py-4 mx-1",attrs:{"id":"moreFilters","color":"pink","x-small":"","dark":""},on:{"click":function($event){$event.stopPropagation();return _vm.toggleSideBar($event)}}},'v-btn',attrs,false),on),[_c('span',{staticClass:"text-h4"},[_vm._v(" + ")]),_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-filter ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('moreFilters'))+" ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"py-4 mx-1",attrs:{"id":"columns","color":"indigo","x-small":"","dark":""},on:{"click":function($event){$event.stopPropagation();return _vm.toggleSideBarColumns($event)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-table-column-plus-after ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('columns'))+" ")])]),_c('v-btn',{staticClass:"mx-2",attrs:{"color":"success"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.guide($event)}}},[_vm._v(" "+_vm._s(_vm.$t('guide.button'))+" ")])],1)],2)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }