// https://github.com/kamranahmedse/driver.js
import i18n from '@/locale'; // Internationalization

const steps = () => [
  {
    element: '#fetchSameValueInMultipleFieldsValueEditable',
    popover: {
      title: i18n.t('guideIndex.fetchSameValueInMultipleFieldsValueEditable.title'),
      description: i18n.t('guideIndex.fetchSameValueInMultipleFieldsValueEditable.description'),
      position: 'botton',
    },
  },
  {
    element: '#clearFilters',
    popover: {
      title: i18n.t('guideIndex.clearFilters.title'),
      description: i18n.t('guideIndex.clearFilters.description'),
      position: 'botton',
    },
  },
  {
    element: '#search',
    popover: {
      title: i18n.t('guideIndex.search.title'),
      description: i18n.t('guideIndex.search.description'),
      position: 'botton',
    },
  },
  {
    element: '#moreFilters',
    popover: {
      title: i18n.t('guideIndex.moreFilters.title'),
      description: i18n.t('guideIndex.moreFilters.description'),
      position: 'botton',
    },
  },
  {
    element: '#columns',
    popover: {
      title: i18n.t('guideIndex.columns.title'),
      description: i18n.t('guideIndex.columns.description'),
      position: 'botton',
    },
  },
];

export default steps;
