<template>
  <!-- eslint-disable max-len -->
  <v-navigation-drawer
    v-model="sidebarColumnsInterno"
    absolute
    temporary
    right
    @input="toggleSideBarColumnsPai"
  >
    <template v-slot:prepend>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            <v-row justify="space-between">
              <v-col>
                {{ $t('Colunas') }}
              </v-col>
              <v-col class="d-flex flex-row-reverse">
                <v-btn
                  color="pink"
                  dark
                  x-small
                  @click.stop="toggleSideBarColumns"
                >
                  X
                </v-btn>
              </v-col>
            </v-row>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <v-divider />
    <v-card-title>
      <v-list>
        <template v-for="(coluna, colunaIndex) in tabelaEnvio">
          <v-list-item
            v-if="coluna.isColumn"
            :key="colunaIndex"
          >
            <v-list-item-action>
              <v-checkbox
                v-model="listaColunasVisiveis[colunaIndex]"
                color="primary"
                @change="togglelistaColunasVisiveis()"
              />
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title
                v-text="coluna.text"
              />
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-card-title>
  </v-navigation-drawer>
</template>

<script>

export default {
  name: 'BuscaResultadoTabelaApiColunas',
  props: {
    sidebarColumns: {
      type: Boolean,
    },
    tabelaEnvio: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    listaColunasVisiveis: [],
    sidebarColumnsInterno: false,
  }),
  updated() {
    this.sidebarColumnsInterno = this.sidebarColumns ? this.sidebarColumns : false;
  },
  mounted() {
    console.log(this.tabelaEnvio);
    this.sidebarColumnsInterno = this.sidebarColumns ? this.sidebarColumns : false;
    this.listaColunasVisiveis = this.extraiListaColunasVisiveisDaTabela();
  },
  methods: {
    togglelistaColunasVisiveis() {
      this.$emit('visibleColumns', this.listaColunasVisiveis);
    },
    extraiListaColunasVisiveisDaTabela() {
      const lista = [];
      this.tabelaEnvio.forEach((coluna, indexColuna) => {
        lista[indexColuna] = coluna.visibleColumn;
      });
      return lista;
    },
    retornaEditavelPai(pai) {
      return pai;
    },
    toggleSideBarColumnsPai() {
      if (this.sidebarColumnsInterno !== this.sidebarColumns) {
        this.$emit('toggleSideBarColumns', this.sidebarColumnsInterno);
      }
    },
    toggleSideBarColumns() {
      this.sidebarColumnsInterno = !this.sidebarColumnsInterno;
      this.$emit('toggleSideBarColumns', this.sidebarColumnsInterno);
    },
  },
};
</script>
