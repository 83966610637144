import i18n from '@/locale'; // Internationalization

export default () => [
  {
    banco: 'idNup',
    text: 'NUP',
    query: 'whenWhereLike',
    /* [
    *  'orWhere'
    *  'whenWhereLike',
    *  'whenWhereSimples',
    *  'whenWhereLikeFilho',
    *  'whenWhereSimplesFilho',
    *  'whenWhereDatas',
    *  'whenWhereBooleanSN',
    *  'whenWhereFull',
    *  'whenWhereArrayFilho',
       ]
       *
       * no back em app/Filters/FiltrosTrait
       * */
    filho: '',
    neto: '',
  },
  {
    banco: i18n.t('denuncias.card.titulo'),
    text: 'Número Denúncia',
    query: 'orWhere',
    filho: '',
    neto: '',
  },
  {
    banco: 'conteudo',
    text: 'Conteúdo Denúncia',
    query: 'orWhere',
    filho: '',
    neto: '',
  },
  {
    banco: 'observacoes',
    text: 'Observações',
    query: 'orWhere',
    filho: '',
    neto: '',
  },
  {
    banco: 'municipio',
    text: 'Município',
    query: 'orWhere',
    filho: '',
    neto: '',
  },
  {
    banco: 'uf',
    text: 'Estado',
    query: 'orWhere',
    filho: '',
    neto: '',
  },
  {
    banco: 'processo',
    text: 'Processo Interessado',
    query: 'whenWhereLikeFilho',
    filho: 'nomeInteressado',
    neto: '',
  },
];
