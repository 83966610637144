import i18n from '@/locale'; // Internationalization

export default () => [
  {
    text: i18n.t('denuncias.card.titulo'),
    explanation: i18n.t('denuncias.resultados_busca'),
    icon: 'mdi-hanger', // icon
    key: 'id', // chave no redireciomaneto
    url: 'teste01', // url quando há redirecionamento
    type: 'apagar', // ['apagar',] //nome da ação q vai p back para poder ajustar o model
    cause: 'axios', // ['axios', 'redirect'] // ação pode realizar ajax ou redirecionar
    roles: ['Desenvolvedor', 'ICA', 'SDOP'], // roles q podem ver o icon
    color: 'pink', // cor do icon
    can: false,
    confirmationRequired: true, // se precisa de modal de confirmação p executar
    conditional: [{ key: 'regional', value: 4 }], // condição em função do conteudo da linha
  },
];
