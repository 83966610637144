<template>
  <v-card-title>
    <v-hover
      v-slot:default="{hover}"
      open-delay="200"
    >
      <v-card
        :elevation="hover ? 16 : 2"
        width="100%"
      >
        <v-row
          align="baseline"
          justify="center"
        >
          <v-col
            v-if="fetchSameValueInMultipleFields.length > 0"
            :cols="11"
            :sm="6"
            :md="5"
            :lg="4"
            class="my-0 py-0"
          >
            <v-row
              align="baseline"
              justify="center"
            >
              <v-col>
                <v-text-field
                  id="fetchSameValueInMultipleFieldsValueEditable"
                  v-model="fetchSameValueInMultipleFieldsValueEditable"
                  dense
                  :label="$t('fetchSameValueInMultipleFieldsValue')"
                  @keyup.enter="apiSearch()"
                />
              </v-col>
              <v-col
                cols="auto"
                class="mr-5"
              >
                <widget-truncate-leia-mais
                  :title="$t('lista_campos_busca')"
                  :text="fetchSameValueInMultipleFieldsJoinText"
                  botao-text="mdi-information-outline"
                />
              </v-col>
            </v-row>
          </v-col>
          <template v-for="(coluna, colunaIndex) in tabelaEnvio">
            <v-col
              v-if="!coluna.isColumn &&
                coluna.filtro.able &&
                coluna.filtro.posicao === 'central' &&
                coluna.filtro.type === 'string'"
              :key="coluna.item"
              :cols="coluna.filtro.column.cols"
              :sm="coluna.filtro.column.sm"
              :lg="coluna.filtro.column.lg"
              :class="coluna.filtro.column.classe"
            >
              <v-text-field
                v-model="valoresEditaveis[colunaIndex]"
                dense
                :rules="coluna.filtro.rules"
                :label="coluna.filtro.label"
                @keyup.enter="apiSearch()"
              />
            </v-col>
            <v-col
              v-if="!coluna.isColumn &&
                coluna.filtro.able &&
                coluna.filtro.posicao === 'central' &&
                coluna.filtro.type === 'select'"
              :key="coluna.item"
              :cols="coluna.filtro.column.cols"
              :sm="coluna.filtro.column.sm"
              :lg="coluna.filtro.column.lg"
              :class="coluna.filtro.column.classe"
            >
              <v-autocomplete
                v-model="valoresEditaveis[colunaIndex]"
                :items="coluna.filtro.items"
                :label="coluna.filtro.label"
                :multiple="coluna.filtro.multiple"
                item-text="texto"
                item-value="id"
              />
            </v-col>
            <v-col
              v-if="!coluna.isColumn &&
                coluna.filtro.able &&
                coluna.filtro.posicao === 'central' &&
                coluna.filtro.type === 'date'"
              :key="coluna.item"
              :cols="coluna.filtro.column.cols"
              :sm="coluna.filtro.column.sm"
              :lg="coluna.filtro.column.lg"
              :class="coluna.filtro.column.classe"
            >
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{on, attrs}">
                  <v-text-field
                    v-model="valoresEditaveis[colunaIndex][0]"
                    dense
                    :label="`${coluna.filtro.label} inicial`"
                    readonly
                    v-bind="attrs"
                    class="mx-5 mb-0 mt-1"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="valoresEditaveis[colunaIndex][0]"
                  @input="menu1 = false"
                />
              </v-menu>
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{on, attrs}">
                  <v-text-field
                    v-model="valoresEditaveis[colunaIndex][1]"
                    dense
                    :label="`${coluna.filtro.label} final`"
                    readonly
                    v-bind="attrs"
                    class="mx-5 my-0"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="valoresEditaveis[colunaIndex][1]"
                  @input="menu2 = false"
                />
              </v-menu>
            </v-col>
          </template>

          <v-col
            cols="auto"
            class="my-0 py-0"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{on, attrs}">
                <v-btn
                  id="clearFilters"
                  x-small
                  v-bind="attrs"
                  class="py-4 mx-1 red lighten-4"
                  :disabled="tableLoading"
                  v-on="on"
                  @click="clearFilters()"
                >
                  <v-icon dark>
                    mdi-filter-remove
                  </v-icon>
                </v-btn>
              </template>
              <span>
                {{ $t('clearFilters') }}
              </span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{on, attrs}">
                <v-btn
                  id="search"
                  x-small
                  v-bind="attrs"
                  :disabled="tableLoading"
                  class="py-4 mx-1 primary"
                  v-on="on"
                  @click="apiSearch()"
                >
                  <!--          https://materialdesignicons.com/cdn/2.0.46/-->
                  <v-icon dark>
                    mdi-magnify
                  </v-icon>
                  <span
                    v-if="retornoApi.length > 0"
                    class="ml-2"
                  >
                    ({{ total }})
                  </span>
                </v-btn>
              </template>
              <span>
                {{ $t('button.search') }}
              </span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{on, attrs}">
                <v-btn
                  id="moreFilters"
                  color="pink"
                  x-small
                  v-bind="attrs"
                  class="py-4 mx-1"
                  dark
                  v-on="on"
                  @click.stop="toggleSideBar"
                >
                  <span class="text-h4">
                    +
                  </span>
                  <v-icon dark>
                    mdi-filter
                  </v-icon>
                </v-btn>
              </template>
              <span>
                {{ $t('moreFilters') }}
              </span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{on, attrs}">
                <v-btn
                  id="columns"
                  color="indigo"
                  x-small
                  v-bind="attrs"
                  class="py-4 mx-1"
                  dark
                  v-on="on"
                  @click.stop="toggleSideBarColumns"
                >
                  <v-icon dark>
                    mdi-table-column-plus-after
                  </v-icon>
                </v-btn>
              </template>
              <span>
                {{ $t('columns') }}
              </span>
            </v-tooltip>
            <v-btn
              class="mx-2"
              color="success"
              @click.prevent.stop="guide"
            >
              {{ $t('guide.button') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-hover>
  </v-card-title>
</template>

<script>
import Vue2Filters from 'vue2-filters';
import WidgetTruncateLeiaMais from '../WidgetTruncateLeiaMais.vue';

export default {
  name: 'BuscaResultadoTabelaApiBusca',
  components: {
    WidgetTruncateLeiaMais,
  },
  mixins: [Vue2Filters.mixin],
  props: {
    sidebar: {
      type: Boolean,
    },
    sidebarColunas: {
      type: Boolean,
    },
    tableLoading: {
      type: Boolean,
    },
    valores: {
      type: Array,
      default: () => [],
    },
    retornoApi: {
      type: Array,
      default: () => [],
    },
    fetchSameValueInMultipleFields: {
      type: Array,
      default: () => [],
    },
    fetchSameValueInMultipleFieldsValue: {
      type: String,
      default: '',
    },
    total: {
      type: Number,
      default: 0,
    },
    tabelaEnvio: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    date: new Date().toISOString().substr(0, 10),
    menu: false,
    modal: false,
    menu1: false,
    menu2: false,
    search: '',
    fetchSameValueInMultipleFieldsValueEditable: '',
    fetchSameValueInMultipleFieldsJoinText: '',
    quantidade: '',
    valoresEditaveis: [],
    sidebarInterno: false,
    sidebarColunasInterno: false,
  }),
  computed: {
  },
  updated() {
    this.sidebarInterno = this.sidebar ? this.sidebar : false;
    this.sidebarColunasInterno = this.sidebarColunas ? this.sidebarColunas : false;
    this.valoresEditaveis = this.retornaEditavelPai(this.valores);
  },
  mounted() {
    this.fetchSameValueInMultipleFieldsJoinText = this.fetchSameValueInMultipleFields.map((cada) => cada.text).join(', ');
    this.valoresEditaveis = this.retornaEditavelPai(this.valores);
  },
  methods: {
    guide() {
      this.$emit('showGuide');
    },
    retornaEditavelPai(pai) {
      return pai;
    },
    toggleSideBarColumns() {
      this.sidebarColunasInterno = !this.sidebarColunasInterno;
      this.$emit('toggleSideBarColumns', this.sidebarColunasInterno);
    },
    toggleSideBar() {
      this.sidebarInterno = !this.sidebarInterno;
      this.$emit('toggleSideBar', this.sidebarInterno);
    },
    clearFilters() {
      this.fetchSameValueInMultipleFieldsValueEditable = null;
      this.valoresEditaveis.forEach((coluna, colunaIndex) => {
        if (typeof coluna === 'object') {
          if (coluna) {
            coluna.forEach((data, dataIndex) => {
              this.valoresEditaveis[colunaIndex][dataIndex] = null;
            });
          }
        } else {
          this.valoresEditaveis[colunaIndex] = this.tabelaEnvio[colunaIndex].filtro.valor;
        }
      });
      this.$emit('clear');
    },
    apiSearch() {
      this.$emit('submitFilho', {
        fetchSameValueInMultipleFieldsValue: this.fetchSameValueInMultipleFieldsValueEditable,
        valores: this.valoresEditaveis,
      });
    },
  },
};
</script>
