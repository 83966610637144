import i18n from '@/locale'; // Internationalization

const dataTable = () => [
  {
    key: 'numeroDenuncia', // key do campo, usado p buscar preferencias
    text: i18n.t('denuncias.resultado.table_headers.numeroDenuncia'),
    // texto q aparece na coluna da tabela
    explicacao: i18n.t('denuncias.resultado.table_headers.numeroDenuncia_explicacao'),
    // texto q aparece na coluna da tabela quando clica em 'i'
    orderable: true, // se a coluna vai poder ser ordenavel
    value: 'numeroDenuncia',
    // item do objeto, normalmente coluna do banco
    // mas tb pode nome da relationship
    filho: null,
    // quando há relationship, item da relacao
    // é usado quando type é object
    isColumn: true, // é coluna na tabela
    visibleColumn: true, // aparecer na tabela como coluna
    type: 'string', // ['string','date','object',select]
    headerClasse: 'text-subtitle-2 text-left', // classe do header da tabela
    rowsClasse: ' text-left', // classe de cada linha da tabela
    link: { // quando há um link na linha da coluna
      estatico: 'portaria-portal/',
      key: 'id',
      api: 'sysaga2',
    },
    filtro: { // metadados dos filtros
      able: true, // se há filtro
      rules: [], // regras de validação
      // rules: [this.rules.min4, this.rules.max100],
      // rules: {
      //   required: (value) => !!value || 'Required.',
      //   max100: (value) => (value || '').length <= 100 || 'Max 100 characters',
      //   min4: (value) => (value && value.length >= 4) || 'Min 4 characters',
      // },
      valor: null, // valor inicial do imput do filtro
      type: 'string', // ['string', 'select','date']
      posicao: 'coluna', // ['coluna','central','sideBar']
      query: 'whenWhereLike',
      /* [
      *  'whenWhereLike',
      *  'whenWhereSimples',
      *  'whenWhereLikeFilho',
      *  'whenWhereDatas',
      *  'whenWhereBooleanSN',
      *  'whenWhereFull',
      *  'whenWhereSimplesFilho',
      *  'whenWhereArrayFilho',
         ]
         *
         * no back em app/Filters/FiltrosTrait
         * */
      nome_banco: 'numeroDenuncia', /* nome da primeira relacao  */
      nome_banco_filho: 'nup', /* nome da segunda relacao  */
      column: { // em v-col valores das colunas
        cols: 6,
        sm: 6,
        lg: 3,
      },
      classe: 'my-0 py-0', // classe do filtro
      label: i18n.t('denuncias.resultado.table_headers.numeroDenuncia'),
      // texto q aparece usando i18n
      // multiple: false, // se o select é multiple
      // items: [ //opções do select
      //   { id: '1', texto: 'oi' },
      //   { id: '2', texto: 'oi 22' },
      //   { id: '3', texto: 'oi 333' },
      // ],
    },
  },
  {
    key: 'nup', // key do campo, usado p buscar preferencias
    text: i18n.t('denuncias.resultado.table_headers.nup'),
    // texto q aparece na coluna da tabela
    explicacao: i18n.t('denuncias.resultado.table_headers.nup_explicacao'),
    // texto q aparece na coluna da tabela quando clica em 'i'
    orderable: true, // se a coluna vai poder ser ordenavel
    value: 'processo',
    // item do objeto, normalmente coluna do banco
    // mas tb pode nome da relationship
    filho: 'nup',
    // quando há relationship, item da relacao
    // é usado quando type é object
    isColumn: true, // aparecer na tabela como coluna
    visibleColumn: true, // aparecer na tabela como coluna
    type: 'object', // ['string','date','object']
    headerClasse: 'text-subtitle-2 text-left', // classe do header da tabela
    rowsClasse: ' text-left', // classe de cada linha da tabela
    filtro: { // metadados dos filtros
      able: true, // se há filtro
      rules: [], // regras de validação
      // rules: [this.rules.min4, this.rules.max100],
      // rules: {
      //   required: (value) => !!value || 'Required.',
      //   max100: (value) => (value || '').length <= 100 || 'Max 100 characters',
      //   min4: (value) => (value && value.length >= 4) || 'Min 4 characters',
      // },
      valor: null, // valor inicial do imput do filtro
      type: 'string', // ['string', 'select','date']
      posicao: 'coluna', // ['coluna','central','sideBar']
      query: 'whenWhereLikeFilho',
      /* [
     *  'whenWhereLike',
     *  'whenWhereSimples',
     *  'whenWhereLikeFilho',
     *  'whenWhereDatas',
     *  'whenWhereBooleanSN',
     *  'whenWhereFull',
     *  'whenWhereSimplesFilho',
     *  'whenWhereArrayFilho',
        ]
        *
        * no back em app/Filters/FiltrosTrait
        * */
      nome_banco: 'processo', /* nome da primeira relacao  */
      nome_banco_filho: 'nup', /* nome da segunda relacao  */
      column: { // em v-col valores das colunas
        cols: 6,
        sm: 6,
        lg: 3,
      },
      classe: 'my-0 py-0', // classe do filtro
      label: i18n.t('denuncias.resultado.table_headers.nup'),
      // texto q aparece usando i18n
      multiple: false, // se o select é multiple
      items: [ // opções do select
        { id: '1', texto: 'oi' },
        { id: '2', texto: 'oi 22' },
        { id: '3', texto: 'oi 333' },
      ],
    },
  },
  {
    key: 'tipoProcesso', // key do campo, usado p buscar preferencias
    text: i18n.t('denuncias.resultado.table_headers.processo_tipo'),
    value: 'processo',
    filho: 'tipoProcesso',
    isColumn: false,
    visibleColumn: false, // aparecer na tabela como coluna
    type: 'object',
    headerClasse: 'text-subtitle-2 text-left',
    rowsClasse: ' text-left',
    filtro: {
      able: true,
      rules: [],
      valor: null,
      type: 'select',
      posicao: 'sideBar', // ['coluna','central','sideBar']
      query: 'whenWhereLikeFilho',
      nome_banco: 'processo', /* nome da primeira relacao  */
      nome_banco_filho: 'tipoProcesso', /* nome da segunda relacao  */
      column: {
        cols: 6,
        sm: 6,
        lg: 3,
      },
      classe: 'my-0 py-0',
      label: i18n.t('denuncias.resultado.table_headers.processo_tipo'),
      multiple: false,
      items: [
        { id: '1', texto: 'Aeródromo' },
        { id: '2', texto: 'OPEA' },
      ],
    },
  },
  {
    key: 'conteudo', // key do campo, usado p buscar preferencias
    text: i18n.t('denuncias.resultado.table_headers.conteudo'),
    explicacao: '',
    // texto q aparece na coluna da tabela quando clica em 'i'
    orderable: true, // se a coluna vai poder ser ordenavel
    value: 'conteudo',
    isColumn: true,
    visibleColumn: false, // aparecer na tabela como coluna
    type: 'string',
    headerClasse: 'text-subtitle-2 text-left',
    rowsClasse: ' text-left',
    link: {
      estatico: 'portaria-portal/',
      key: 'id',
      api: 'sysaga2',
    },
    filtro: {
      able: true,
      rules: [],
      // rules: [this.rules.min4, this.rules.max100],
      valor: null,
      type: 'string',
      posicao: 'coluna', // ['coluna','central','sideBar']
      query: 'whenWhereLike',
      nome_banco: 'conteudo',
      column: {
        cols: 6,
        sm: 6,
        lg: 3,
      },
      classe: 'my-0 py-0',
      label: i18n.t('denuncias.resultado.table_headers.conteudo'),
      // label: i18n.t('denuncias.resultado.table_headers.nup'),
    },
  },
  {
    key: 'regional', // key do campo, usado p buscar preferencias
    text: i18n.t('denuncias.resultado.table_headers.regional'),
    explicacao: i18n.t('denuncias.resultado.table_headers.regional_explicacao'),
    // texto q aparece na coluna da tabela quando clica em 'i'
    orderable: true, // se a coluna vai poder ser ordenavel
    value: 'regional',
    isColumn: true,
    visibleColumn: true, // aparecer na tabela como coluna
    type: 'select',
    headerClasse: 'text-subtitle-2 text-center',
    rowsClasse: ' text-center',
    opcoes: [
      { id: '0', texto: '' },
      { id: '1', texto: 'C1' },
      { id: '2', texto: 'C2' },
      { id: '3', texto: 'C3' },
      { id: '4', texto: 'C4' },
      { id: '5', texto: 'SP' },
    ],
    filtro: {
      able: true,
      rules: [],
      valor: null,
      type: 'select',
      posicao: 'coluna', // ['coluna','central','sideBar']
      query: 'whenWhereSimples',
      nome_banco: 'regional',
      column: {
        cols: 6,
        sm: 6,
        lg: 3,
      },
      classe: 'my-0 py-0',
      label: i18n.t('denuncias.resultado.table_headers.regional'),
      multiple: false,
      items: [
        { id: '1', texto: 'C1' },
        { id: '2', texto: 'C2' },
        { id: '3', texto: 'C3' },
        { id: '4', texto: 'C4' },
        { id: '5', texto: 'SP' },
      ],
    },
  },
  {
    key: 'created_at', // key do campo, usado p buscar preferencias
    text: i18n.t('denuncias.resultado.table_headers.created_at'),
    explicacao: i18n.t('denuncias.resultado.table_headers.created_at_explicacao'),
    // texto q aparece na coluna da tabela quando clica em 'i'
    orderable: true, // se a coluna vai poder ser ordenavel
    value: 'created_at',
    isColumn: true,
    visibleColumn: true, // aparecer na tabela como coluna
    type: 'date',
    headerClasse: 'text-subtitle-2 text-center',
    rowsClasse: ' text-center',
    link: {
      estatico: 'portaria-portal/',
      key: 'id',
      api: 'sysaga2',
    },
    filtro: {
      able: true,
      // rules: [this.rules.min4, this.rules.max100],
      rules: [],
      valor: [null, null],
      type: 'date',
      posicao: 'coluna', // ['coluna','central','sideBar']
      query: 'whenWhereDatas',
      nome_banco: 'created_at',
      column: {
        cols: 6,
        sm: 6,
        lg: 3,
      },
      classe: 'my-0 py-0',
      label: i18n.t('denuncias.resultado.table_headers.created_at'),
    },
  },
];

export default dataTable;
